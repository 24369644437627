<
<template>
  <div class="app-ecommerce">
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-12 col-lg-5">
          <div class="card mb-4">
            <div class="card-header">
              <h4 class="card-tile mb-0">Ajouter un Nouvel Attribut</h4>
              <label style="margin-top: 15px"
                >Les attributs vous permettent de définir des données
                supplémentaires pour les produits, telles que la taille ou la
                couleur. Vous pouvez utiliser ces attributs dans la colonne
                latérale de la boutique en utilisant les widgets « filtrage par
                attribut ».</label
              >
            </div>
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label" for="ecommerce-product-name"
                  >Nom</label
                >
                <input
                  attribut
                  v-model="attribut.name"
                  type="text"
                  class="form-control"
                  placeholder="Nom Attribut "
                  name="productTitle"
                  aria-label="Product title"
                />
              </div>
              <!-- <ListeTerme /> -->
              <div class="mb-3" v-if="attribut.name">
                <label class="form-label" for="ecommerce-product-name"
                  >Terme Liste</label
                >
                <b-card>
                  <div ref="form" class="repeater-form">
                    <div
                      v-for="(term, index) in termData"
                      :key="index"
                      ref="termRow"
                      class="pb-2"
                    >
                      <!-- Term Name Form -->

                      <div class="d-flex border rounded">
                        <b-col>
                          <label>Term Name:</label>
                          <b-form-input
                            v-model="term.termName"
                            class="mb-2 item-selector-title"
                            placeholder="Enter Term Name"
                          />
                        </b-col>

                        <div
                          class="d-flex flex-column justify-content-between border-left py-50 px-25"
                        >
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeTerm(index)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    @click="addNewTerm"
                  >
                    Add Term
                  </b-button>
                </b-card>
              </div>
            </div>
            <div
              class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3"
            >
              <div class="d-flex flex-column justify-content-center">
                <h4 class="mb-1 mt-3"></h4>
              </div>
              <div class="d-flex align-content-center flex-wrap gap-3">
                <div class="d-flex gap-3 mr-1">
                  <button
                    :disabled="attribut.name.length === 0"
                    @click="AddAttributes()"
                    class="btn btn-primary"
                  >
                    Ajouter Attribut
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-7">
          <div class="card">
            <b-table
              :items="items"
              :fields="fields"
              striped
              hover
              responsive
              :busy="isBusy"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong style="margin-left: 5px">Loading...</strong>
                </div>
              </template>
              <template #cell(name)="row">
                <h5>
                  {{ row.item.name }}
                </h5>
              </template>
              <template #cell(terms)="row">
                <div>
                  <b-badge
                    v-for="item in row.item.terms"
                    :key="item.id"
                    style="margin: 5px"
                    variant="light-primary"
                    class="badge-glow"
                  >
                    {{ item.name }}
                  </b-badge>
                </div>
              </template>
              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <label
                  ><a @click="RedirectToConfigureTerm(data.item.id)"
                    >Configurer les termes</a
                  ></label
                >
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import axios from "axios";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Cleave from "vue-cleave-component";
import ListeTerme from "./ListeTerme.vue";
import vSelect from "vue-select";
import websiteURL from "@/websiteURL";
import { quillEditor } from "vue-quill-editor";
import { getAttributeTerms } from "../../services/products/Products";
import {
  VBToggle,
  BOverlay,
  BPagination,
  BFormInput,
  BCardText,
  BButton,
  BIcon,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BAvatar,
  BTable,
  BSpinner,
  BImg,
  BLink,
  BTooltip,
  bIcon,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    ListeTerme,
    BOverlay,
    BPagination,
    BFormInput,
    BCardText,
    vSelect,
    BSpinner,
    BTooltip,
    BDropdownItem,
    BDropdown,
    BIcon,
    BTable,
    BImg,
    BLink,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      loading: false,
      termData: [],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      isBusy: false,
      items: [],
      fields: ["name", "terms", "actions"],
      attribut: {
        name: "",
      },
    };
  },
  computed: {},
  methods: {
    async RedirectToConfigureTerm(item) {
      console.log(item);
      this.$router.push({
        name: "Configure Terme",
        params: { id: item },
      });
    },
    clearData() {
      (this.termData = []), (this.attribut.name = "");
    },
    addNewTerm() {
      this.termData.push({ termName: "" });
    },
    removeTerm(index) {
      this.termData.splice(index, 1);
    },
    async AddAttributes() {
      this.loading = true;
      try {
        const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products/attributes";
        const consumerKey = websiteURL.CONSUMERKEY;
        const consumerSecret = websiteURL.CONSUMERSECRET;
        const response = await axios.post(apiUrl, this.attribut, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });

        if (response.status === 201) {
          const attributeId = response.data.id;
          this.isBusy = true;

          for (const term of this.termData) {
            await this.addTermToAttribute(attributeId, term.termName);
          }

          this.items = await getAttributeTerms(attributeId);
          this.isBusy = false;
          this.loading = false;
          this.clearData();
        } else {
          console.error("Error adding attribute:", response.data);
          alert("Error adding attribute:", response.data);
        }
      } catch (error) {
        console.error("Error adding product:", error);
      }
    },
    async addTermToAttribute(attributeId, term) {
      try {
        const termApiUrl =
          websiteURL.WEBSITE +
          `wp-json/wc/v3/products/attributes/${attributeId}/terms`;
        const consumerKey = websiteURL.CONSUMERKEY;
        const consumerSecret = websiteURL.CONSUMERSECRET;

        // Create a new term object to send to the API
        const termData = {
          name: term,
        };

        const response = await axios.post(termApiUrl, termData, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });

        if (response.status === 201) {
          console.log(`Term added: ${term}`);
        } else {
          console.error("Error adding term:", response.data);
        }
      } catch (error) {
        console.error("Error adding term:", error);
      }
    },
  },
  async created() {},

  async mounted() {
    this.isBusy = true;
    this.items = await getAttributeTerms();
    this.isBusy = false;
  },
};
</script>
<style lang="scss">
// Style
.ql-snow {
  &.ql-container {
    .ql-editor {
      min-height: 200px;
    }
  }
}
.currency-input {
  position: relative;

  input {
    padding-left: 3rem;
    width: 100%;
  }

  .currency-symbol {
    position: absolute;
    top: 50%;
    left: 0;
    width: 3rem;
    text-align: center;
    transform: translateY(-50%);
  }

  input:invalid ~ .currency-symbol {
    opacity: 0.5;
  }
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

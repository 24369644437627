<template>
  <b-card-code title="Repeating Forms">
    <div>
      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <!-- Item Name -->
          <b-col md="5">
            <b-form-group>
              <div class="form-group-content">
                <label for="name" class="label">Nom</label>

                <feather-icon
                  v-b-tooltip.hover
                  style="margin-left: 2px; cursor: help"
                  icon="HelpCircleIcon"
                  id="tooltip-target-1"
                ></feather-icon>

                <b-tooltip target="tooltip-target-1" triggers="hover">
                  Le nom est la façon dont il apparaît sur votre site.
                </b-tooltip>
              </div>

              <b-form-input
                type="text"
                id="name"
                placeholder="Vuexy Admin Template"
              />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group>
              <div class="form-group-content">
                <label for="name" class="label">Slug</label>

                <feather-icon
                  v-b-tooltip.hover
                  style="margin-left: 2px; cursor: help"
                  icon="HelpCircleIcon"
                  id="tooltip-target-2"
                ></feather-icon>

                <b-tooltip target="tooltip-target-2" triggers="hover">
                  Le « slug » est la version du nom normalisée pour les URL. Il
                  ne contient généralement que des lettres, des chiffres et des
                  traits d’union.
                </b-tooltip>
              </div>

              <b-form-input
                type="text"
                id="name"
                placeholder="Vuexy Admin Template"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col md="2" class="mb-50">
            <b-button
              style="margin-top: 22px"
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              @click="removeItem(index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>Add New</span>
    </b-button>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BTooltip,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BTooltip,
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    attribute: Array,
    variant: Array,
  },
  data() {
    return {
      items: [
        {
          id: 1,
          selected: "male",
          selected1: "designer",
          prevHeight: 0,
        },
      ],
      nextTodoId: 2,
    };
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
